import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessagingService } from 'src/app/demo/V3/services/messaging.service';
import { SearchService } from 'src/app/demo/V3/services/search.service';
import { AustraliaProfileData, AustraliaRequestData } from '../../../models/SearchRequestModel';
import { Shared } from 'src/app/demo/V3/services/shared';
import { SearchType } from '../../../models/SearchType';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { PersonHistoryResultModel } from '../../../models/AustraliaResultModel/PersonHistoryResultModel';
import { AustraliaSearchService } from 'src/app/demo/V3/services/australia.search.service';
import { EnhancedRecordPlusResultModel } from '../../../models/AustraliaResultModel/EnhancedRecordPlusResultModel';
declare var $: any;

@Component({
  selector: 'app-au-personal',
  templateUrl: './au-personal.component.html',
  styleUrls: ['./au-personal.component.css', '../../shared/shared.searchpage.css']
})
export class AuPersonalComponent implements OnInit {
  @ViewChild('pdf') pdf: PDFExportComponent;

  public personalForm: FormGroup;
  public validationType: any = {
    'reference': [Validators.required],
    'name': [Validators.required],
    'email': [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')],
    'phone': [Validators.pattern('^[0-9]{10}$')]
  };

  public showResultTab: boolean = false;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";

  // Result Model
  public personHistoryResultModel: PersonHistoryResultModel = new PersonHistoryResultModel();
  public enhancedRecordPlanResultModel: EnhancedRecordPlusResultModel = new EnhancedRecordPlusResultModel();

  // PDF
  public requestDataModel: AustraliaRequestData = new AustraliaRequestData();

  constructor(
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private australiaSearchService: AustraliaSearchService,
    private searchService: SearchService,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);

    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.personalForm = this.formBuilder.group({
      profile: this.formBuilder.group({
        audit: [""],
        reference: [""],
        name: [""],
        dob: [""],
        phone: [""],
        email: [""],
        streetaddress: [""],
        suburb: [""],
        state: [""],
        postcode: [""],
      }),
    });
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  // Search button click event
  public personalSearch(): void {
    this.addValidators();
    this.personalForm.updateValueAndValidity();

    if (this.personalForm.valid) {
      Promise.resolve(this.getRandomString(10)).then(
        (data) => {

          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: AustraliaRequestData = this.prepareRequestData();

          this.setActiveResultTab();

          // Person History
          this.GetPersonHistory(requestObj);

          // Enhanced Record Plus
          this.GetEnhanceRecordPlus(requestObj);
        }
      );
    }
  }

  public GetPersonHistory(requestData: AustraliaRequestData) {
    this.personHistoryResultModel.isLoading = false;
    this.personHistoryResultModel.responseData = null;
    this.personHistoryResultModel.searchFail = false;
    this.personHistoryResultModel.validation = false;

    if (requestData.profileData.firstName && requestData.profileData.lastName &&
      (requestData.profileData.dateOfBirth || requestData.profileData.phoneNumber || requestData.profileData.email)) {
      this.personHistoryResultModel.isLoading = true;
      this.australiaSearchService.GetPersonHistory(requestData).subscribe(data => {
        this.personHistoryResultModel.isLoading = false;
        data["data"] = JSON.parse(data["data"]);
        this.personHistoryResultModel.responseData = data;
      }, error => {
        this.personHistoryResultModel.searchFail = true;
        this.personHistoryResultModel.isLoading = false;
        console.log("Person History Error: ", error);
      })
    } else {
      this.personHistoryResultModel.validation = true;
    }
  }

  public GetEnhanceRecordPlus(requestData: AustraliaRequestData) {
    this.enhancedRecordPlanResultModel.isLoading = false;
    this.enhancedRecordPlanResultModel.responseData = null;
    this.enhancedRecordPlanResultModel.searchFail = false;
    this.enhancedRecordPlanResultModel.validation = false;

    console.log("Request Data: ", requestData.profileData);
    if (requestData.profileData.firstName && requestData.profileData.lastName && requestData.profileData.phoneNumber &&
      requestData.profileData.email && (requestData.profileData.streetAddress || requestData.profileData.dateOfBirth)
    ) {
      this.enhancedRecordPlanResultModel.isLoading = true;
      this.australiaSearchService.GetEnhancedRecordPlus(requestData).subscribe(data => {
        this.enhancedRecordPlanResultModel.isLoading = false;
        data["data"] = JSON.parse(data["data"]);
        this.enhancedRecordPlanResultModel.responseData = data;
      }, error => {
        this.enhancedRecordPlanResultModel.searchFail = true;
        this.enhancedRecordPlanResultModel.isLoading = false;
        console.log("Enhanced Record Plan Error: ", error);
      })
    } else {
      this.enhancedRecordPlanResultModel.validation = true;
    }
  }

  public personalClearSearch(): void {
    this.personalForm.controls['profile'].reset();
    this.clearValidators();
    this.resetResultObjects();
    this.showResultTab = false;
  }



  public setActiveResultTab() {
    setTimeout(() => {
      // Tab Header
      var navLine = $('.nav-link');
      $.each(navLine, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Tab Result
      var tabPane = $('.tab-pane');
      $.each(tabPane, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Show first tab as active
      $('#personhistory-search-tab').addClass("active");
      $('#personHistorySearch').addClass("active");
    }, 2000);
  }

  // Prepare the request object for API call
  public prepareRequestData(): AustraliaRequestData {
    // Profile object framing
    var profileData = new AustraliaProfileData();
    profileData.auditKey = this.personalForm.get('profile.audit').value;
    profileData.referenceNumber = this.personalForm.get('profile.reference').value;
    profileData.fullName = this.personalForm.get('profile.name').value.trim();

    if (this.personalForm.get('profile.name').value) {
      let searchtextarray = this.personalForm.get('profile.name').value.trim().split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }

    profileData.dateOfBirth = this.personalForm.get('profile.dob').value;
    profileData.phoneNumber = this.personalForm.get('profile.phone').value;
    profileData.email = this.personalForm.get('profile.email').value;
    profileData.streetAddress = this.personalForm.get('profile.streetaddress').value;
    profileData.suburb = this.personalForm.get('profile.suburb').value;
    profileData.state = this.personalForm.get('profile.state').value;
    profileData.postcode = this.personalForm.get('profile.postcode').value;

    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchType = SearchType.Personal.toString();
    profileData.searchUsername = this.loggedInUsername;

    // RequestData object framing
    var requestObj = new AustraliaRequestData();
    requestObj.profileData = profileData;
    return requestObj;
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  public generatePDF() {
    var requestObj: AustraliaRequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    if (this.requestDataModel.profileData.phoneNumber) {
      this.requestDataModel.profileData.phoneNumber = this.personalForm.get('profile.phone').value;
    }

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 2000);

  }

  public resetResultObjects(): void {
    this.personHistoryResultModel = new PersonHistoryResultModel();
    this.enhancedRecordPlanResultModel = new EnhancedRecordPlusResultModel();
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.personalForm.patchValue({
          profile: {
            audit: data
          }
        });
      });
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const topControls in this.personalForm.controls) {
      if (topControls == 'profile') {
        let childControls = this.personalForm.controls[topControls] as FormGroup
        for (const child in childControls.controls) {
          try {
            this.personalForm.get(topControls + "." + child).clearValidators();
            this.personalForm.get(topControls + "." + child).setValidators(this.validationType[child]);
            this.personalForm.get(topControls + "." + child).updateValueAndValidity();
          } catch (err) { }
        }
      }
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public clearValidators(): void {
    this.personalForm.patchValue({
      audit: "",
      reference: "",
      name: "",
      dob: "",
      phone: "",
      email: "",
      streetaddress: "",
      suburb: "",
      state: "",
      postcode: "",
    });
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;

    for (const topControls in this.personalForm.controls) {
      if (topControls == 'profile') {
        let childControls = this.personalForm.controls[topControls] as FormGroup
        for (const child in childControls.controls) {
          try {
            this.personalForm.get(topControls + "." + child).clearValidators();
            this.personalForm.get(topControls + "." + child).updateValueAndValidity();
          } catch (err) { }
        }
      }
    }
  }

}
