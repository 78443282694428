<app-search-top-nav></app-search-top-nav>
<section id="mobile-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-mobile-RO.png" />
    </div>
    <form [formGroup]="mobileForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-8 offset-md-2 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="mobileForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Mobile:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="mobile" autocomplete="off" (input)="onMobileChange()" />
                      <p class="text-danger" *ngIf="mobileForm.get('mobile').errors?.required">
                        <strong><small>Mobile number is required</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="mobileForm.get('mobile').errors?.pattern">
                        <strong><small>Please enter UK numbers only</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2" (click)="mobileSearch()">Search <i class="fa fa-search" aria-hidden="true"></i></button>
                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              (click)="generatePDF()"
                              *ngIf="saveAsPDFButtonVisibility"
                              [disabled]="mobileResult?.isLoading || opensourceResult?.isLoading || prospectResultModel.isProspectHitLoading || addressResultModel?.isLoading">
                        Save as PDF
                      </button>
                      <button class="btn colorRed reset-btn mx-2"
                              (click)="mobileClearSearch()"
                              *ngIf="clearSearchButtonVisibility"
                              [disabled]="mobileResult?.isLoading || opensourceResult?.isLoading || prospectResultModel.isProspectHitLoading || addressResultModel?.isLoading">
                        Clear Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container mb-5" *ngIf="showResultTab">
      <div class="mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
          <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="address-search-tab" data-toggle="tab" href="#mobileSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Mobile</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="aml-search-tab" data-toggle="tab" href="#opensourceSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Opensource</b></a>
            </li>
            <li class="nav-item" *ngIf="prospectResultModel.isProspectHitAccess && !regionAccess.isAURegion">
              <a class="nav-link" id="prospect-search-tab" data-toggle="tab" href="#prospectSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
            </li>
            <li class="nav-item" *ngIf="!regionAccess.isAURegion">
              <a class="nav-link" id="address-search-tab" data-toggle="tab" href="#addressSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Contact</b></a>
            </li>
          </ul>

          <div class="tab-content" id="searchResultTabContent">
            <br />
            <!---------------------- Category Search table for Main results --------------->
            <!-- Mobile Results -->
            <div class="tab-pane fade show tab-height active" id="mobileSearch" role="tabpanel" aria-labelledby="address-search-tab">
              <app-mobile-result-mobile [mobile]="mobileResult"></app-mobile-result-mobile>
            </div>

            <!-- Opensource Result -->
            <div class="tab-pane fade show tab-height" id="opensourceSearch" role="tabpanel" aria-labelledby="aml-search-tab">
              <app-opensource-result-mobile [opensource]="opensourceResult"></app-opensource-result-mobile>
            </div>

            <!-- Prospect Result -->
            <div class="tab-pane fade show tab-height" id="prospectSearch" role="tabpanel" aria-labelledby="prospect-search-tab">
              <app-prospect-result-personal [prospect]="prospectResultModel" (prospectProfileRequestEventEmitter)="prospectProfileRequest()"></app-prospect-result-personal>
            </div>

            <!-- Address Result -->
            <div class="tab-pane fade show tab-height" id="addressSearch" role="tabpanel" aria-labelledby="address-search-tab">
              <app-address-result-personal [address]="addressResultModel"></app-address-result-personal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-1"></div>
  </div>
</section>
<p-confirmDialog key="ProspectProfileRequest" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<div style="position: absolute; top: 0; right: 120%;">
  <kendo-pdf-export #pdf>
    <app-export-pdf-mobile [requestData]="requestDataModel"
                           [mobile]="mobileResult"
                           [opensource]="opensourceResult"
                           [prospect]="prospectResultModel"
                           [address]="addressResultModel"
                           [regionAccess]="regionAccess"></app-export-pdf-mobile>
  </kendo-pdf-export>
</div>
