import { Injectable } from '@angular/core';
import { AustraliaRequestData, RequestData } from '../search-pages/models/SearchRequestModel';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AustraliaSearchService {

  constructor(private http: HttpClient) { }

  public GetMotorWebSearchResult(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3AustraliaSearch/GetMotorWebSearch', requestData);
  }

  public GetVehicleIdSearchResult(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3AustraliaSearch/GetVehicleIdSearch', requestData);
  }

  public GetPersonHistory(requestData: AustraliaRequestData): Observable<any> {
    return this.http.post<any>('api/V3AustraliaSearch/GetPersonHistory', requestData);
  }

  public GetEnhancedRecordPlus(requestData: AustraliaRequestData): Observable<any> {
    return this.http.post<any>('api/V3AustraliaSearch/GetEnhancedRecordPlus', requestData);
  }

}
